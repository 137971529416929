.bottom-box {
    grid-area: bottom-box;

    display: grid;
    grid-template-rows: 33% 67%;
    grid-template-areas:
        "new-message"
        "bottom-box__buttons";

    padding: 1rem;
    padding-bottom: 0;

    background-color: var(--color-background-lighter);
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.bottom-box--conversation-closed {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
        "bottom-box__buttons";
}

.new-message {
    grid-area: new-message;

    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    gap: 1rem;
}
.new-message--conversation-closed {
    display: none;
}
.new-message__form {
    display: flex;
    width: 100%;
    align-items: center;
}
.bottom-box__close {
    padding: 0.3rem;

    border: 0.15rem solid var(--color-primary);
    border-radius: 50%;
}
.bottom-box__buttons {
    grid-area: bottom-box__buttons;

    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;
}

@media only screen and (min-width: 600px) {
    .bottom-box {
        grid-area: bottom-box;

        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: auto 6rem;
        grid-template-areas:
            "new-message bottom-box__buttons";

        padding: 1rem;
        padding-bottom: 0;

        background-color: var(--color-background-lighter);
        border-radius: 0;
        box-shadow: 0 0 0;
    }
    .bottom-box__buttons {
        flex-direction: row-reverse;
    }
}

.hide {
    display: none;
}
.show-flex {
    display: flex;
}
