.search-bar {
    grid-area: search-bar;

    display: none;
    flex-direction: row;
    margin: 0.8rem;
}

@media only screen and (min-width: 600px) {
    .search-bar {
        display: flex;
    }
}